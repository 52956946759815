import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';



@Component({
  selector: 'app-delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent  {

  constructor(
    public dialogRef: MatDialogRef<DeleteButtonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}


  ngOnInit() {}

  save(){
    this.dialogRef.close(this.data);
  }

}
