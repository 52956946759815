import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EmpresaService } from '../../service/empresa/empresa.service';
import { Empresa } from '../../models/empresa/empresa';
import { MatSelectModule } from '@angular/material/select';


@Component({
  selector: 'app-combo-empresas',
  templateUrl: './combo-empresas.component.html',
  styleUrls: ['./combo-empresas.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComboEmpresasComponent implements OnInit {

  public empresas: Empresa[];
  public empresa: Empresa = new Empresa();
  public selectedValue = 'selecione';
  constructor(private empresaService: EmpresaService) { }

  ngOnInit() {
    this.empresaService.getEmpresas()
      .subscribe(data => {
        const lista: any = data;
        this.empresas = lista.items;

      },
        err => {
          console.log(err);
        });
  }

  onChange() {

  }

}
