import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Perfil } from '../../models/perfil/perfil';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

 // Headers
 httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
};

  private url = this.hosts.apiUrl + '/v1/roles';
  
  constructor(private http: HttpClient) { }

  getPerfil(): Observable<Perfil[]>{
    return this.http.get<Perfil[]>(this.url, this.httpOptions);
  }
}
