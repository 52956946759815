import { Component, OnInit } from '@angular/core';
import { Filial } from '../../models/filial/filial';
import { Device } from '../../models/device/device';
import { ActivatedRoute } from '@angular/router';
import { FilialService } from '../../service/filial/filial.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.css']
})
export class CameraComponent implements OnInit {

  public filial: Filial = new Filial();
  public cameras: Device[];

  constructor(
    private route: ActivatedRoute,
    private filialService: FilialService
  ) { }

  ngOnInit() {
    this.filial.id = this.route.snapshot.paramMap.get('id');
    this.filialService.getListDevices(this.filial).subscribe(
      data => {
        const lista: any = data;
        this.cameras = lista;
      },
      err => {
        console.log(err);
      }
    );
  }

}
