import { Component } from '@angular/core';
import { AuthService } from './login/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'fluxPanel';

  exibirMenu: boolean = false;

  constructor(private authService: AuthService){

  }
  ngOnInit(){
    this.authService.exibirMenuEmitter.subscribe(
      exibir => this.exibirMenu = exibir
    );
  }
}
