import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Features } from '../../models/features/features';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

  private url = this.hosts.apiUrl + '/v1/features';
  
  constructor(private http: HttpClient) { }

   // Headers
   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
  };

  getAll(): Observable<Features[]> {
    return this.http.get<Features[]>(this.url, this.httpOptions);
  }

}