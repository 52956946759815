import { Component, OnInit, Inject } from '@angular/core';
import { EmpresaService } from '../../service/empresa/empresa.service';
import { Empresa } from '../../models/empresa/empresa';
import { FilialService } from '../../service/filial/filial.service';
import { Filial } from './../../models/filial/filial';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NgModel } from '@angular/forms';

export interface DialogData {
  token: string;
  name: string;
  cnpj: string;
}

@Component({
  selector: 'app-listar-filial',
  templateUrl: './listar-filial.component.html',
  styleUrls: ['./listar-filial.component.css']
})
export class ListarFilialComponent implements OnInit {

  public empresas: Empresa[];
  public empresa: Empresa = new Empresa();
  public selectedValue = '';
  public filial: Filial = new Filial();
  public filiais: Filial[];
  public main: boolean;
  constructor(
    private empresaService: EmpresaService, 
    private filialService: FilialService,
    // tslint:disable-next-line: align
    private snackBar: MatSnackBar, 
    public dialog: MatDialog) { }

  ngOnInit() {
    this.empresaService.getEmpresas()
      .subscribe(data => {
        const lista: any = data;
        this.empresas = lista.items;
      },
        err => {
          console.log(err);
        });
  }

  changeCompany() {
    this.filial.companyId = this.selectedValue;
    this.filialService.getFiliais(this.filial).
      subscribe(data => {
        const lista: any = data;
        this.filiais = lista.items;
      },
        err => {
          console.log(err);
        });
  }
  lockUnlocked(mainValue) {
    const filialUpd = new Filial();
    filialUpd.id = mainValue.id;
    filialUpd.name = mainValue.name;
    filialUpd.active = !mainValue.active;
    let msg: string;
    mainValue.active ? msg = 'Desativado' : msg = 'Ativado';
    this.filialService.lockUnlocked(filialUpd).
      subscribe(resp => {
        this.filiais.filter((ele) => {
          if (ele.id === filialUpd.id) {
            ele.active = filialUpd.active;
          }
        });
        this.notify(msg);
      },
        err => {
          this.notify(err.error.message);
        });
  }

  syncDevices(item: Filial) {
    this.filialService.postSyncDevices(item).
      subscribe(data => {
        this.notify('Dispositivo sincronizados com sucesso.');
      }, (err) => {
        this.notify(err.error.mensagem, 'error');
      });
  }

  notify(msg: string, type: string = 'success') {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = type;
    this.snackBar.open(msg, 'Close', config);
  }

  exibirToken(token: Filial) {
    {
      const dialogRef = this.dialog.open(DialogDataExampleDialog, {
        data: {
          token: token.apitoken,
          cnpj: token.cnpj,
          name: token.name
        }
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  findFilial(idFilial : any){

    localStorage.setItem('idFilial',idFilial);

  }

}

@Component({
  selector: 'app-dialog-data-dialog',
  templateUrl: 'dialog-token.html',
})
export class DialogDataExampleDialog {
  constructor( public dialogRef: MatDialogRef<DialogDataExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

    onNoClick(): void {
      this.dialogRef.close();
    }
}
