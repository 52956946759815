import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Zona } from '../../models/zona/zona';
import { ZonaService } from '../../service/zona/zona.service';
import { FilialService } from '../../service/filial/filial.service';
import { Filial } from '../../models/filial/filial';
import { DeleteButtonComponent } from '../../diretivas/delete-button/delete-button.component';


@Component({
  selector: 'app-zona',
  templateUrl: './zona.component.html',
  styleUrls: ['./zona.component.css']
})
export class ZonaComponent implements OnInit {

  public zona: Zona = new Zona();
  public filial: Filial = new Filial();
  public zonas: Zona[];

  constructor(private zonaService: ZonaService
    ,private filialService: FilialService
    ,private snackBar: MatSnackBar
    ,private route: ActivatedRoute
    ,private router: Router
    ,public dialog: MatDialog,

  ){}


  ngOnInit() {
    this.filial.id = this.route.snapshot.paramMap.get('id');
    this.filialService.getZones(this.filial)
    .subscribe(data => {
      const lista: any = data;
      this.zonas = lista.items;

    },
      err => {
        console.log(err);
      });
  }

  /**
   *
   * @param msg
   * @param msnClass classe para decorar a mensagem sucess | error
   * @param msnDuration  tempo que será exibida a mensagem na tela. 3000 para sucesso e 5000 para erro
   */
  notify(msg: string, msnClass: string, msnDuration: number) {
    const config = new MatSnackBarConfig();
    config.duration = msnDuration;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = msnClass;
    this.snackBar.open(msg, 'Close', config);
  }

  updateGrid(){
    this.filialService.getZones(this.filial)
    .subscribe(data => {
      const lista: any = data;
      this.zonas = lista.items;

    },
      err => {
        console.log(err);
      });
  }

}
