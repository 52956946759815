import { Features } from "../features/features";

export class Filial {
  id: string;
  name: string;
  cnpj: string;
  main: boolean;
  companyId: string;
  active: boolean;
  apitoken: string;
  items?: [];
  qtd_licensa: number;
  features?: [];
  list?: any[];
}
