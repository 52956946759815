export class Usuario {
  username: string;
  password: string;
  token: string;
  role = {
    name: '' ,
    role: ''
  };
  actives: boolean;
  id: number;
  active: boolean;
  name: string;
  email: string;
  roles: string
}
