import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Usuario } from '../../models/login/usuario';
import { Observable, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UsuarioFilialService {

  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

  private urlFiliais = this.hosts.apiUrl + '/v1/branchoffices';
  private urlUsers = this.hosts.apiUrl + '/v1/users';

    // Headers
    httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
    };

    constructor(private http: HttpClient) { }

    getUsers(id:  string): Observable<Usuario[]>{
      return this.http.get<Usuario[]>(`${this.urlFiliais}/${id}/users`, this.httpOptions);
    }

    lockUnlocked(usuario: Usuario): Observable<Usuario> {
      const body = JSON.stringify({ id: usuario.id, status: usuario.active });
      return this.http.patch<Usuario>(`${this.urlUsers}/active`, body, this.httpOptions);
    }

    delete(usuario: Usuario){
      return this.http.delete(`${this.urlUsers}/${usuario.id}`, this.httpOptions);
    }

}
