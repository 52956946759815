import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ZonaService } from '../../../service/zona/zona.service';
import { Zona } from '../../../models/zona/zona';
import { Filial } from '../../../models/filial/filial';
import { FilialService } from '../../../service/filial/filial.service';
import { Device } from '../../../models/device/device';

@Component({
  selector: 'app-cadastrar-zona',
  templateUrl: './cadastrar-zona.component.html',
  styleUrls: ['./cadastrar-zona.component.css']
})
export class CadastrarZonaComponent implements OnInit {


  public zona: Zona = new Zona();
  public filial: Filial = new Filial();
  public devices: any[];
  public reload = false;

  public nome = new FormControl('', [Validators.required]);

  constructor(
          private zonaService: ZonaService
        , private filialService: FilialService
        ,private snackBar: MatSnackBar
        ,private route: ActivatedRoute
        ,private router: Router
    ) {
  }

  ngOnInit() {
    this.filial.id = this.route.snapshot.paramMap.get('id');
    this.inicializarDevices(this.filial);
    this.zona.branchOfficeId = this.filial.id;
  }

  onSubmit(){

    this.reload = true;
    if (this.nome.hasError('required') || (this.zona.secondaryZone === undefined)) {
      return false;
    } else{
      let numeros:any = [];
      // this.featuresInsert = this.features.filter( this.filtrarId );
      this.devices.filter(elem => {
        if (elem.checked == true){
          numeros.push(elem.name );
        }
      });
      this.zona.codes = numeros;
      if(this.zona.codes.length == 0){
        this.notify('Selecione ao menos 1 device para realizar o cadastro.');
        return;
      }

      this.zonaService.add(this.zona).
      subscribe(success => {

        this.notify('Created!');
        this.clearFields();
      },
        err => {
          console.log(err);
          this.notify(err.message);
          this.reload = false;
        });
    }
  }

  inicializarDevices(filial: Filial): any{
    const listaArray = [];
    this.filialService.getListDevices(filial)
    .subscribe(data => {
       const lista: any = data;
       lista.forEach(element => {
       const objeto = {
         checked: false,
          name: element.code,
          disable: element.selected == null ? true : false
        }
        listaArray.push(objeto);
      });
      this.devices = listaArray;
    },
      err => {
        console.log(err);
      });
   }

   clearFields() {
    this.reload = false;
    this.nome.reset();
    this.zona.secondaryZone = null;
    this.zona = new Zona();
    this.zona.branchOfficeId = this.filial.id;
    this.inicializarDevices(this.filial);
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

  getErrorMessage() {
    return 'Campo Obrigatório.';
  }

  delete(id: string){
    const zona = new Zona();
    zona.id = id;
    this.zonaService.delete(zona)
    .subscribe(data => {

      this.ngOnInit();
    },
      err => {
        console.log(err);
      });
  }

}
