import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { EmpresaService } from '../service/empresa/empresa.service';
import { Empresa } from '../models/empresa/empresa';
import { Filial } from './../models/filial/filial';
import { FilialService } from '../service/filial/filial.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FeatureService } from '../service/feature/feature.service';
import { Features } from '../models/features/features';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  name: string;
  id: number;
}


@Component({
  selector: 'app-filial',
  templateUrl: './filial.component.html',
  styleUrls: ['./filial.component.css']
})
export class FilialComponent implements OnInit {

  public empresas: Empresa[];
  public features: Features[];
  public featuresInsert: number[];
  public empresa: Empresa = new Empresa();
  public reload = false;
  public selectedValue = 'selecione';
  public filial: Filial = new Filial();
  public nome = new FormControl('', [Validators.required]);
  public cnpj = new FormControl('', [Validators.required]);
  public empresaId = new FormControl('', [Validators.required]);
  public qtd_licensa = new FormControl('', [Validators.required]);
  public main = new FormControl('', []);
  public selectdValues = {};



  constructor(private empresaService: EmpresaService, private filialService: FilialService
    , private featureService: FeatureService
    // tslint:disable-next-line: align
    , private snackBar: MatSnackBar
    , public dialog: MatDialog) { }


  ngOnInit() {
    this.empresaService.getEmpresas()
      .subscribe(data => {
        const lista: any = data;
        this.empresas = lista.items;
      },
        err => {
          console.log(err);
        });
        this.inicializarFeatures();
  }

  onSubmit() {
    this.reload = true;
    if (this.nome.hasError('required') || this.cnpj.hasError('required')
      || this.empresaId.hasError('required') || this.qtd_licensa.hasError('required')
    ) {
      return false;
    } else {
      let numeros = [];
      // this.featuresInsert = this.features.filter( this.filtrarId );
      this.features.filter(elem => {
        if (elem.checked == true){
          numeros.push(elem.id );
        }
      });
      this.filial.list = numeros;
      this.filialService.add(this.filial).
        subscribe(success => {

          this.notify('Created!');
          this.clearFields();
        },
          err => {
            console.log(err);
            this.notify('Error reported!');
            this.reload = false;
          });
    }
  }

  clearFields() {
    this.reload = false;
    this.nome.setValue('');
    this.cnpj.setValue('');
    this.empresaId.setValue('');
    this.qtd_licensa.setValue('');
    this.main.setValue(false);
    this.nome.reset();
    this.cnpj.reset();
    this.empresaId.reset();
    this.qtd_licensa.reset();
    this.main.reset();
    this.inicializarFeatures();
    this.filial = new Filial();
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

  getErrorMessage() {
    return 'Campo Obirigatório.';
  }

 inicializarFeatures(){
  this.featureService.getAll()
  .subscribe(data => {
    const lista: any = data;
    lista.items.forEach(element => {
      element.checked = false;
    });
    this.features = lista.items;
  },
    err => {
      console.log(err);
    });
 }

}
