import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Empresa } from '../models/empresa/empresa';
import { EmpresaService } from '../service/empresa/empresa.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styleUrls: ['./empresa.component.css']
})
export class EmpresaComponent implements OnInit {

  public empresa = new Empresa();
  public nomeEmpresa = new FormControl('', [Validators.required]);
  public reload = false;
  private erro = false;
  private message;
  constructor(private empresaService: EmpresaService
    // tslint:disable-next-line: align
    , private snackBar: MatSnackBar) { }

  ngOnInit() { }

  onSubmit() {
    this.reload = true;
    if (this.nomeEmpresa.hasError('required')) {
      return false;
    } else {
      this.empresa.name = this.nomeEmpresa.value;
      this.empresa.image = '';
      this.empresaService.add(this.empresa).subscribe(
        (dep) => {

          this.notify('Created!');
          this.clearFields();
        },
        (err) => {
          console.log(err);
          this.notify('Error reported!');
        }
      );

    }
  }

  clearFields() {
    this.reload = false;
    this.nomeEmpresa.setValue('');
  }

  getErrorMessageNomeEmpresa() {
    if (this.nomeEmpresa.hasError('required')) {
      return 'O campo Nome da Empresa é obrigatório.';
    }
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

  openUploadDialog() { }
}
