export class Zona {
    id: string;
    name: string;
    branchOfficeId: string;
    entranceId?: number;
    codes?: [];
    code?: any;
    devices?: [];
    secondaryZone: boolean;
  }


  export interface ZonaData {
    id: number;
    name: string;
    branchOfficeId: number;

  }
