import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FilialComponent } from './filial/filial.component';
import { EmpresaComponent } from './empresa/empresa.component';
import { LoginComponent } from './login/login.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { ListarFilialComponent } from './filial/listar-filial/listar-filial.component';
import { ListarEmpresaComponent } from './empresa/listar-empresa/listar-empresa.component';
import { ListarUsuarioFilialComponent } from './filial/listar-usuario-filial/listar-usuario-filial.component';
import { CadastroUsuarioFilialComponent } from './filial/cadastro-usuario-filial/cadastro-usuario-filial.component';
import { ZonaComponent } from './filial/zona/zona.component';
import { CadastrarZonaComponent } from './filial/zona/cadastrar-zona/cadastrar-zona.component';
import { EditarZonaComponent } from './filial/zona/editar/editar-zona.component';
import { CameraComponent } from './filial/camera/camera.component';
import { CadastrarCameraComponent } from './filial/camera/cadastrar-camera/cadastrar-camera.component';
import { EditarCameraComponent } from './filial/camera/editar-camera/editar-camera.component';

 /**Mapeando rotas das paginas de navegação */
const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login'},
  // { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home-dashboard', component: HomeDashboardComponent },
  { path: 'empresa', component: EmpresaComponent },
  { path: 'listar-empresa', component: ListarEmpresaComponent },
  { path: 'filial', component: FilialComponent },
  { path: 'listar-filial', component: ListarFilialComponent },
  { path: 'listar-usuario-filial/:id', component: ListarUsuarioFilialComponent },
  { path: 'cadastrar-usuario-filial/:id', component: CadastroUsuarioFilialComponent },
  { path: 'zona/:id', component: ZonaComponent },
  { path: 'zona/cadastrar/:id', component: CadastrarZonaComponent },
  { path: 'zona/editar/:id', component: EditarZonaComponent },
  { path: 'device/:id', component: CameraComponent},
  { path: 'device/cadastrar/:id', component: CadastrarCameraComponent},
  { path: 'device/editar/:id', component: EditarCameraComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

