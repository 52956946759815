import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from './auth.service';
import { Usuario } from '../models/login/usuario';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private usuario: Usuario = new Usuario();
  username = new FormControl('', [Validators.required]);
  password = new FormControl('', [Validators.required]);
  public reload = false;
  public erro = false;
  private message;
  constructor(private authService: AuthService) { }
  ngOnInit() { }

  getErrorMessageCnpj() {
    if (this.username.hasError('required')) {
      return 'O campo Cnpj é obrigatório.';
    }
  }
  getErrorMessage() {
    if (this.password.hasError('required')) {
      return 'O campo senha é obrigatório.';
    }
  }

  onLogin() {
    this.reload = true;
    if (
      this.username.hasError('required') || this.password.hasError('required')
    ) {
      return false;
    } else {
      this.usuario.username = this.username.value;
      this.usuario.password = this.password.value;
      this.authService.onLogin(this.usuario);

    }
  }

  logOff() {
    this.authService.limpaUsuarioAutenticado();
    this.authService.exibirMenuEmitter.emit(false);
    this.usuario = new Usuario();
  }

  getError() {
    return this.message;
  }
}
