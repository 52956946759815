import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Empresa } from '../../models/empresa/empresa';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {
  private empresaSubjects$: BehaviorSubject<Empresa[]> = new BehaviorSubject<Empresa[]>(null);

  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

  private url = this.hosts.apiUrl + '/v1/companies';


  constructor(private router: Router, private http: HttpClient,
    // tslint:disable-next-line: align
    private snackBar: MatSnackBar) { }

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token),
  };

  getEmpresas(): Observable<Empresa[]> {
    return this.http.get<Empresa[]>(this.url, this.httpOptions);
  }
  add(emp: Empresa): Observable<Empresa> {
    const body = JSON.stringify({ name: emp.name, image: emp.image });
    return this.http.post<Empresa>(this.url, body, this.httpOptions
    );
  }
}
