import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import  { FilialService }  from '../../service/filial/filial.service';
import { Filial } from '../../models/filial/filial';
import { UsuarioFilialService } from '../../service/usuario-filial/usuarioFilial.service';
import { Usuario } from '../../models/login/usuario';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DeleteButtonComponent } from '../../diretivas/delete-button/delete-button.component';


@Component({
  selector: 'app-listar-usuario-filial',
  templateUrl: './listar-usuario-filial.component.html',
  styleUrls: ['./listar-usuario-filial.component.css']
})
export class ListarUsuarioFilialComponent implements OnInit {

  public filial: Filial = new Filial();
  public usuarios: Usuario[];
  public usuario: Usuario = new Usuario();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private filialService: FilialService,
    private usuarioFilialService: UsuarioFilialService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog

  ) { }

  ngOnInit() {


    this.filial.id = this.route.snapshot.paramMap.get('id');

    this.filialService.getFilial(this.filial)
    .subscribe(data => {
      this.filial = data;
          this.usuarioFilialService.getUsers(this.filial.id)
          .subscribe(dados => {
            const lista: any = dados;
            this.usuarios = lista.items;
          },
          err => {
            console.log(err);
          });
    },
      err => {
        console.log(err);
      });

  }

  lockUnlocked(mainValue) {
    const usuarioFilial = new Usuario();
    usuarioFilial.id = mainValue.id;
    usuarioFilial.active = !mainValue.active;
    let msg: string;
    mainValue.active ? msg = 'Desativado' : msg = 'Ativado';
    this.usuarioFilialService.lockUnlocked(usuarioFilial).
      subscribe(resp => {
        this.usuarios.filter((ele) => {
          if (ele.id === usuarioFilial.id) {
            ele.active = usuarioFilial.active;
          }
        });

        this.notify(msg,'sucess',3000);
      },
        err => {
          this.notify(err.error.message,'err',5000);
        });
  }

  delete(id: string){
    const dialogRef = this.dialog.open( DeleteButtonComponent,  {
      data: {
        id: id
      }
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      this.usuario.id = parseInt( result.id);
        this.usuarioFilialService.delete(this.usuario)
        .subscribe(data => {
          if(data){
            this.updateGrid();
            this.notify('Registro removido com sucesso!','sucess',3000);
          }else{
            this.notify('Ocorreu um erro ao tentar apagar o registro!','err',5000);
          }

        },
          err => {
            console.log(err);
            this.notify('Ocorreu um erro ao tentar apagar o registro!','error',5000);
          });

    });
  }


    /**
   *
   * @param msg
   * @param msnClass classe para decorar a mensagem sucess | error
   * @param msnDuration  tempo que será exibida a mensagem na tela. 3000 para sucesso e 5000 para erro
   */
  notify(msg: string, msnClass: string, msnDuration: number) {
    const config = new MatSnackBarConfig();
    config.duration = msnDuration;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = msnClass;
    this.snackBar.open(msg, 'Close', config);
  }


  updateGrid(){
    this.filialService.getFilial(this.filial)
    .subscribe(data => {
      this.filial = data;
          this.usuarioFilialService.getUsers(this.filial.id)
          .subscribe(dados => {
            const lista: any = dados;
            this.usuarios = lista.items;
          },
          err => {
            console.log(err);
          });
    },
      err => {
        console.log(err);
      });
  }

}
