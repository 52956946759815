import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UsuarioFilial } from '../../models/usuario-filial/usuario-filial';
import { FilialService } from '../../service/filial/filial.service';
import { PerfilService } from '../../service/perfil/perfil.service';
import { Perfil } from '../../models/perfil/perfil';
@Component({
  selector: 'app-cadastro-usuario-filial',
  templateUrl: './cadastro-usuario-filial.component.html',
  styleUrls: ['./cadastro-usuario-filial.component.css']
})
export class CadastroUsuarioFilialComponent implements OnInit {

  public filialUser = new UsuarioFilial();
  public email = new FormControl('', [Validators.required, Validators.email]);
  public perfil_v = new FormControl('', [Validators.required]);
  public reload = false;
  public perfis: Perfil[];

  constructor(private filialService: FilialService
    , private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private perfilService: PerfilService) { }

  ngOnInit() {
    this.email.reset();
    this.filialUser.companyId = this.route.snapshot.paramMap.get('id');
    this.perfilService.getPerfil() .subscribe(data => {
      const lista: any = data;
      this.perfis = lista.items;

    },
      err => {
        console.log(err);
      });
  };

  onSubmit(){
    this.reload = true;
    if(this.email.hasError('required') || this.email.hasError('email') || this.perfil_v.hasError('required')) {
      return false;
    }else{
      this.filialService.addUser(this.filialUser)
      .subscribe(success => {

        this.notify('Created!');
        this.clearFields();
      },
        err => {
          console.log(err);
          this.notify('Error reported!');
          this.reload = false;
        });
    }


  };

  getErrorMessage() {
      return  'Campo Obirigatório.';
  }

  clearFields() {
    this.reload = false;
    this.filialUser.email = '';
    this.filialUser.password = '';
    this.filialUser.active = false;
    this.email.reset();
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

}
