import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Zona } from '../../models/zona/zona';

@Injectable({
  providedIn: 'root'
})
export class ZonaService {


  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

  private url = this.hosts.apiUrl + '/v1/entrances';

  constructor(private http: HttpClient) { }

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
  };

  add(zona: Zona): Observable<Zona> {
    const body = JSON.stringify({ name: zona.name, branchOfficeId: zona.branchOfficeId, codes: zona.codes, secondaryZone: zona.secondaryZone });
    return this.http.post<Zona>(this.url, body, this.httpOptions);
  }

  put(zona: Zona): Observable<Zona[]> {
    const body = JSON.stringify({  name: zona.name, branchOfficeId: zona.branchOfficeId, codes: zona.codes, secondaryZone: zona.secondaryZone });
    return this.http.put<Zona[]>(`${this.url}/${zona.id}`, body, this.httpOptions);
  }

  get(zona: Zona) : Observable<Zona[]>{
    const body = JSON.stringify({ id: zona.id});
    return this.http.get<Zona[]>(`${this.url}/${zona.id}/zones`, this.httpOptions);
  }

  delete(zona: Zona){
    const body = JSON.stringify({ name: zona.name, branchofficeId: zona.branchOfficeId, codes: zona.codes });
    return this.http.delete(`${this.url}/${zona.id}`, this.httpOptions);
  }

  connetc(zona: Zona): Observable<Zona> {
    const body = JSON.stringify({ entranceId: zona.entranceId, branchofficeId: zona.branchOfficeId, code: zona.code });
    return this.http.post<Zona>(`${this.url}/connect` , body, this.httpOptions);
  }

  getByID(zona: Zona) : Observable<Zona>{
    const body = JSON.stringify({ id: zona.id});
    return this.http.get<Zona>(`${this.url}/${zona.id}`, this.httpOptions);
  }

}
