import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Device } from "../../models/device/device";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    private readonly hosts: any = {
        apiUrl: environment.apiUrl
    };

    private url = this.hosts.apiUrl + '/v1/device';

    constructor(private http: HttpClient) {}

    // Headers
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
    };

    add(device: Device): Observable<Device> {
        const body = JSON.stringify({code: device.name, branchOfficeId: device.branchOfficeId});
        return this.http.post<Device>(this.url, body, this.httpOptions);
    }

    getById(device: Device) : Observable<Device> {
        return this.http.get<Device>(`${this.url}/${device.id}`, this.httpOptions);
    }

    put(device: Device): Observable<Device> {
        const body = JSON.stringify({code: device.name, branchOfficeId: device.branchOfficeId});
        return this.http.put<Device>(`${this.url}/${device.id}`, body, this.httpOptions);
    }
}