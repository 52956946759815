import { Component, OnInit } from '@angular/core';
import { Device } from '../../../models/device/device';
import { Filial } from '../../../models/filial/filial';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { DeviceService } from '../../../service/device/device.service';

@Component({
  selector: 'app-cadastrar-camera',
  templateUrl: './cadastrar-camera.component.html',
  styleUrls: ['./cadastrar-camera.component.css']
})
export class CadastrarCameraComponent implements OnInit {

  public device: Device = new Device();
  public filial: Filial = new Filial();
  public reload = false;

  public nome = new FormControl('', [Validators.required]);

  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private deviceService: DeviceService
  ) { }

  ngOnInit() {
    this.filial.id = this.route.snapshot.paramMap.get('id');
    this.device.branchOfficeId = this.filial.id;
  }

  onSubmit(){

    this.reload = true;
    if (this.nome.hasError('required')) {
      return false;
    }

    this.deviceService.add(this.device).subscribe(
      success => {
        this.notify('Created!');
        this.clearFields();
      },
      err => {
        console.log(err);
        this.notify(err.message);
        this.reload = false;
      }
    )
  }

  clearFields() {
    this.reload = false;
    this.nome.reset();
    this.device = new Device();
    this.device.branchOfficeId = this.filial.id;
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

  getErrorMessage() {
    return 'Campo Obrigatório.';
  }
}
