import { Component, OnInit } from '@angular/core';
import { EmpresaService } from '../../service/empresa/empresa.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Empresa } from '../../models/empresa/empresa';
import { NgModel, Form } from '@angular/forms';

@Component({
  selector: 'app-listar-empresa',
  templateUrl: './listar-empresa.component.html',
  styleUrls: ['./listar-empresa.component.css']
})
export class ListarEmpresaComponent implements OnInit {

  constructor(private empresaService: EmpresaService, private router: Router,
    // tslint:disable-next-line: align
    private snackBar: MatSnackBar) { }
    public empresas: Empresa[];

  ngOnInit() {
    this.empresaService.getEmpresas()
      .subscribe(data => {
        const lista: any = data;
        this.empresas = lista.items;
      },
        err => {
          const config = new MatSnackBarConfig();
          this.snackBar.open(err.error.message, 'Close', {
            duration: 3000
            , panelClass: ['color:black']
            , horizontalPosition: 'center'
            , verticalPosition: 'top'
          });
          this.router.navigate(['/']);
        });
  }

  onSubmit() {
    this.router.navigate(['/empresa']);
  }
}
