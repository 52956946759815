import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import {  MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Filial } from '../../../models/filial/filial';
import { Zona } from '../../../models/zona/zona';
import { FilialService } from '../../../service/filial/filial.service';
import { ZonaService } from '../../../service/zona/zona.service';

@Component({
  selector: 'app-editar-zona',
  templateUrl: './editar-zona.component.html',
  styleUrls: ['./editar-zona.component.css']
})
export class EditarZonaComponent implements OnInit {

  public zona: Zona = new Zona();
  public filial: Filial = new Filial();
  public zonas: Zona[];
  public devices: any[];
  public deviceList: any[];
  public reload = false;

  public name = new FormControl('', [Validators.required]);
  constructor(
    private zonaService: ZonaService
    ,private snackBar: MatSnackBar
    ,private route: ActivatedRoute
    ,public dialog: MatDialog

  ) { }

  ngOnInit() {

    this.filial.id =  localStorage.getItem('idFilial') ;

    this.zona.id = this.route.snapshot.paramMap.get('id');
    this.zonaService.getByID(this.zona)
      .subscribe(data => {
        this.zona = data;
        this.zona.branchOfficeId = this.filial.id;
        this.devices = data.devices;
        this.name.setValue(this.zona.name);
        this.formatDevices(data);
      },
      err => {
        console.log(err);
      });

  }

  formatDevices(data: any): any{
    const listaArray = [];
      data.devices.forEach(element => {
        const objeto = {
          checked: element.selected || false,
          name: element.code,
          id: element.id,
          disable: element.selected == null ? true : false
        }
        listaArray.push(objeto);
      });
      this.devices = listaArray;
   }


   onSubmit(){

    this.reload = true;
    if (this.name.hasError('required') ) {
      return false;
    } else{
      let numeros:any = [];
      // this.featuresInsert = this.features.filter( this.filtrarId );
      this.devices.filter(elem => {
        if (elem.checked == true){
          numeros.push(elem.name );
        }
      });
      this.zona.codes = numeros;

      this.zonaService.put(this.zona).
      subscribe(success => {

        this.notify('Updated!');
      },
        err => {
          console.log(err);
          this.notify(err.message);
          this.reload = false;
        });
    }
  }

  notify(msg: string) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.snackBar.open(msg, 'Close', config);
  }

  getErrorMessage() {
    return 'Campo Obirigatório.';
  }

}
