import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Filial } from '../../models/filial/filial';
import { UsuarioFilial } from '../../models/usuario-filial/usuario-filial';
@Injectable({
  providedIn: 'root'
})
export class FilialService {

  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };

  private url = this.hosts.apiUrl + '/v1/branchoffices';
  private urlEmpresas = this.hosts.apiUrl + '/v1/companies';
  private urlUser = this.hosts.apiUrl + '/v1/users';
  constructor(private http: HttpClient) { }

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', localStorage.token)
  };

  add(filial: Filial): Observable<Filial> {
    const body = JSON.stringify({ name: filial.name, cnpj: filial.cnpj, main: filial.main, companyId: filial.companyId, limitDevice: filial.qtd_licensa, features: filial.list });
    return this.http.post<Filial>(this.url, body, this.httpOptions);
  }

  lockUnlocked(filial: Filial): Observable<Filial> {
    const body = JSON.stringify({ id: filial.id, status: filial.active });
    return this.http.patch<Filial>(`${this.url}/active`, body, this.httpOptions);
  }

  getFiliais(filial: Filial): Observable<Filial[]> {
    return this.http.get<Filial[]>(`${this.urlEmpresas}/${filial.companyId}/branchoffice`, this.httpOptions);
  }

  getFilial(filial: Filial): Observable<Filial> {
    return this.http.get<Filial>(`${this.url}/${filial.id}`, this.httpOptions);
  }

  addUser(usuario: UsuarioFilial){
      const bodyUser = JSON.stringify({ username: usuario.email, branchOfficeId: new String(usuario.companyId),
        active: !usuario.active ? false :usuario.active, password: !usuario.password ? '' :usuario.password, roles: String(usuario.role)  });
    return this.http.post<UsuarioFilial>(this.urlUser, bodyUser, this.httpOptions);
  }

  getZones(filial: Filial){
    return this.http.get<Filial[]>(`${this.url}/${filial.id}/zones`, this.httpOptions);
  }

  getDevices(filial: Filial){
    return   this.http.get<Filial[]>(`${this.url}/${filial.id}/devices`, this.httpOptions);
  }

  getListDevices(filial: Filial){
    return   this.http.get<Filial[]>(`${this.url}/${filial.id}/list-devices`, this.httpOptions);
  }

  postSyncDevices(filial: Filial){
    return this.http.post<any>(`${this.url}/${filial.id}/sync-devices`, {}, this.httpOptions);
  }
}
