import { environment } from './../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { Usuario } from '../models/login/usuario';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly hosts: any = {
    apiUrl: environment.apiUrl
  };
  private usuarioAutenticado = false;
  private url = this.hosts.apiUrl + '/v1/login';
  private urlValidate = this.hosts.apiUrl + '/v1/managers/validate';
  private token: string;
  error = {};
  exibirMenuEmitter = new EventEmitter<boolean>(false);
  tokenEmitter = new EventEmitter<string>();
  durationInSeconds = 5;

  constructor(private router: Router, private http: HttpClient,
    // tslint:disable-next-line: align
    private snackBar: MatSnackBar) { }

  // Headers
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  onLogin(usuario: Usuario) {
    const body = JSON.stringify({ username: usuario.username, password: usuario.password });
    return this.http.post<Usuario>(this.url,
      body, this.httpOptions
    ).subscribe(retorno => {
      this.setSession(retorno);
      let ret = this.isGestor(retorno).subscribe(
        sucesso => {
          if (sucesso.validate) {
            // this.setSession(retorno);
            this.usuarioAutenticado = true;
            this.exibirMenuEmitter.emit(true);
            this.tokenEmitter.emit(this.token);

            this.router.navigate(['/home-dashboard']);
          } else {
            this.clearSession();
            this.messageError('Acesso restrito para gestores.');
          }
        }, erro => {
          this.clearSession();
          this.messageError(erro.error.message);
        }
      );
    },
      err => {
        this.clearSession();
        this.messageError(err.error.message);
      });

  }
  // passa momento que ele esta autenticado
  usuarioEstaAutenticado() {
    return this.usuarioAutenticado;
  }

  public limpaUsuarioAutenticado(): void {
    this.usuarioAutenticado = false;
  }


  private setSession(AuthREsult) {
    localStorage.setItem('token', 'Bearer ' + AuthREsult.token);
  }

  private clearSession() {
    localStorage.clear();
  }

  private isGestor(token: any) {
    return this.http.get<any>(this.urlValidate,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }).set('Authorization', 'Bearer ' + token.token) });
  }

  messageError(msg) {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.horizontalPosition = 'center';
    config.verticalPosition = 'top';
    config.panelClass = 'sucess';
    this.exibirMenuEmitter.emit(false);
    this.snackBar.open(msg || 'Problema com sua conexão.', 'Close', config);
    this.router.navigate(['/']);
  }
}
